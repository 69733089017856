
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

@import '~vuetify/src/styles/settings/_variables';

.clientcabin-examples::v-deep {
    background-color: $white;
    color: $black;

    .headline {
        font-size: 48px !important;
        line-height: 60px !important;
        letter-spacing: 0.02em;
        font-weight: 700;
        color: #282b30;
    }

    .subheadline {
        font-size: 40px !important;
        line-height: 48px !important;
        letter-spacing: 0.035em;
        font-weight: 700;
        color: #282b30;
    }

    .subtitle {
        font-size: 20px;
        line-height: 36px;
        font-weight: 600;
    }

    .link {
        cursor: pointer;
    }
}
